import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Productos from './components/Products';
import Navbar from './components/Navbar';
import History from './components/History';
import Login from './components/Login';
import Cart from './components/Cart';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import AdminDashboard from './components/AdminDashboard';
import Company from './components/Company';
import SubscriberList from './components/SubscriberList';
import MassEditor from './components/MassEditor';
import SalesReport from './components/SalesReport';
import RenamedHistorySales from './components/RenamedHistorySales';
import DiscountConfig from './components/DiscountConfig';
import LocalSales from './components/LocalSales';
import StockManagement from './components/StockManagement'; 
import QRCodeComponent from './components/QRCodeComponent'; // Cambiado el path al de componentes
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Success from './components/PaginasMP/success';
import Failure from './components/PaginasMP/failure';
import Pending from './components/PaginasMP/pending';
import WallStreetChart from './components/WallStreetChart';

import './App.css';

const App = () => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const removeFromCart = (id) => {
    setCart(cart.filter(item => item.id !== id));
  };

  const clearCart = () => {
    setCart([]);
  };

  const updateCartItemQuantity = (itemId, newQuantity) => {
    setCart(cart.map(item => item.id === itemId ? { ...item, quantity: newQuantity } : item));
  };

  const recaptchaFrontend = process.env.REACT_APP_RECAPTCHA_FRONTEND;
  const isAdmin = localStorage.getItem('isAdmin') === 'true';
  const userRole = localStorage.getItem('userRole');
  const websiteURL = 'https://elmachobebidas.com'; // Reemplaza con tu URL

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaFrontend}>
      <Router>
        <div className="App">
          <Navbar cart={cart} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/historia" element={<History />} />
            <Route path="/empresa" element={<Company />} />
            <Route path="/contacto" element={<ContactForm />} />
            <Route path="/productos" element={<Productos cart={cart} setCart={setCart} />} />
            <Route path="/carrito" element={<Cart cart={cart} removeFromCart={removeFromCart} clearCart={clearCart} updateCartItemQuantity={updateCartItemQuantity} />} />
            <Route path="/login" element={<Login />} />
            {isAdmin && <Route path="/admin" element={<AdminDashboard />} />}
            {isAdmin && <Route path="/subscriptores" element={<SubscriberList />} />}
            {isAdmin && <Route path="/editor-masivo" element={<MassEditor />} />}
            {isAdmin && <Route path="/historial-ventas" element={<RenamedHistorySales />} />}
            {isAdmin && <Route path="/reporte-de-ventas" element={<SalesReport />} />}
            {(isAdmin || userRole === 'cashier') && <Route path="/local" element={<LocalSales />} />}
            {isAdmin && <Route path="/configuracion-descuentos" element={<DiscountConfig />} />}
            {isAdmin && <Route path="/gestion-stock" element={<StockManagement />} />} 
            {isAdmin && <Route path="/grafico" element={<WallStreetChart />} />}
            <Route path="/qr" element={<QRCodeComponent url={websiteURL} />} /> 

            <Route path="/success" element={<Success />} />
            <Route path="/failure" element={<Failure />} />
            <Route path="/pending" element={<Pending />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </GoogleReCaptchaProvider>
  );
};

export default App;
