import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAWDJ9q8cAB5GIoq6eOYAYjMv0GU0J9-RY",
  authDomain: "elmachobebidas.firebaseapp.com",
  projectId: "elmachobebidas",
  storageBucket: "elmachobebidas.appspot.com",
  messagingSenderId: "838963245562",
  appId: "1:838963245562:web:63065f059f3d726bf3b443",
  measurementId: "G-PV5JN6L2DP"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

export { auth, db,functions };
