import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
import Notification from './Notification';
import Spinner from './Spinner';
import { pinFileToIPFS } from '../pinata';
import './Products.css';

const Products = ({ cart, setCart }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [showProducts, setShowProducts] = useState(false);
  const [notification, setNotification] = useState({ message: '', show: false });
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editProduct, setEditProduct] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const navigate = useNavigate();

  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const isAdmin = localStorage.getItem('isAdmin') === 'true';

  const fetchProducts = useCallback(async () => {
    try {
      const productsCollection = await getDocs(collection(db, 'products'));
      const productsData = productsCollection.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setProducts(productsData);

      const uniqueCategories = [...new Set(productsData.map(product => product.category))];
      setCategories(uniqueCategories);
    } catch (error) {
      console.error("Error fetching products:", error);
      setNotification({ message: 'Error al cargar productos.', show: true });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const addToCart = (product, quantity) => {
    const existingProduct = cart.find(item => item.id === product.id);
    const existingQuantity = existingProduct ? existingProduct.quantity : 0;

    if (product.stock < quantity + existingQuantity) {
      setNotification({ message: 'No hay suficiente stock disponible.', show: true });
      return;
    }

    const updatedCart = existingProduct
      ? cart.map(item => item.id === product.id ? { ...item, quantity: item.quantity + quantity } : item)
      : [...cart, { ...product, quantity }];

    setCart(updatedCart);
    setNotification({ message: `Añadido ${quantity} ${product.model} al carrito.`, show: true });
    setTimeout(() => setNotification({ message: '', show: false }), 1000);
  };

  const handleAction = (action, product) => {
    if (!isAdmin && (action === 'edit' || action === 'delete')) {
      navigate('/login');
      return;
    }

    if (action === 'view') {
      setSelectedProduct(product);
      setShowProducts(false);
    } else if (action === 'edit') {
      setEditProduct(product);
      setIsModalOpen(true);
    } else if (action === 'delete') {
      handleDelete(product);
    }
  };

  const handleDelete = async (product) => {
    const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar este producto?");
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'products', product.id));
        setProducts(products.filter(p => p.id !== product.id));
        setNotification({ message: 'Producto eliminado con éxito.', show: true });
      } catch (error) {
        console.error("Error al eliminar el producto: ", error);
        setNotification({ message: 'Error al eliminar el producto.', show: true });
      } finally {
        setTimeout(() => setNotification({ message: '', show: false }), 3000);
      }
    }
  };

  const handleProductSubmit = async (productData) => {
    setLoading(true);
    try {
      if (editProduct) {
        await updateDoc(doc(db, 'products', editProduct.id), productData);
        setProducts(products.map(p => p.id === editProduct.id ? { ...productData, id: editProduct.id } : p));
        setNotification({ message: 'Producto actualizado con éxito.', show: true });
      } else {
        const docRef = await addDoc(collection(db, 'products'), productData);
        setProducts([...products, { ...productData, id: docRef.id }]);
        setNotification({ message: 'Producto agregado con éxito.', show: true });
      }
      setIsModalOpen(false);
      setEditProduct(null);
      fetchProducts();
    } catch (error) {
      console.error("Error al guardar el producto: ", error);
      setNotification({ message: 'Error al guardar el producto.', show: true });
    } finally {
      setLoading(false);
      setTimeout(() => setNotification({ message: '', show: false }), 3000);
    }
  };

  const filteredProducts = products
    .filter(product => selectedCategory === 'all' || product.category === selectedCategory)
    .sort((a, b) => a.model.localeCompare(b.model)); // Ordena alfabéticamente por el nombre del producto

  if (loading) return <Spinner />;

  return (
    <div className="products-container">
      <Notification message={notification.message} show={notification.show} />
      <div className="category-buttons-container">
        <div className="dropdown-container">
          <button className="category-button" onClick={() => setShowProducts(!showProducts)}>PRODUCTOS POR CATEGORIA</button>
          {showProducts && (
            <div className="dropdown-content-container">
              {categories
                .sort((a, b) => a.localeCompare(b)) // Línea 106: Ordena las categorías alfabéticamente
                .map(category => (
                  <button key={category} onClick={() => { setSelectedCategory(category); setShowProducts(false); }} className="dropdown-content-button">
                    {category}
                  </button>
                ))}
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay-container">
          <div className="modal-content-container">
            <ProductForm
              product={editProduct}
              onSubmit={handleProductSubmit}
              onCancel={() => {
                setIsModalOpen(false);
                setEditProduct(null);
              }}
              categories={categories}
              setCategories={setCategories}
            />
          </div>
        </div>
      )}
      {selectedProduct && (
        <ProductDetailModal
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}
      <div className="ball-list-container">
        {filteredProducts.map((product) => (
          <ProductItem
            key={product.id}
            product={product}
            addToCart={addToCart}
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
            handleAction={handleAction}
          />
        ))}
      </div>
      {isAdmin && <button onClick={() => setIsModalOpen(true)} className="category-button">Agregar Producto</button>}
    </div>
  );
};

const ProductItem = ({ product, addToCart, isAuthenticated, isAdmin, handleAction }) => {
  const [quantity, setQuantity] = useState(1);

  return (
    <div className={`product-item-container ${product.stock === 0 ? 'out-of-stock' : ''}`}>
      <BallCard
        model={product.model}
        image={product.images ? product.images[0] : ''}
        price={`$${formatNumber(product.price)}`}
        onClick={() => handleAction('view', product)}
      />

      <div className="product-actions-container">
        <label htmlFor={`quantity-${product.id}`} className="product-action-label">Cantidad:</label>
        <input
          type="number"
          id={`quantity-${product.id}`}
          value={quantity}
          min={1}
          max={product.stock}
          onChange={(e) => setQuantity(Number(e.target.value))}
          className="product-action-input"
        />
        <button className="product-action-button add" onClick={() => addToCart(product, quantity)} disabled={product.stock === 0}>
          {product.stock > 0 ? 'Agregar al Carrito' : 'Sin Stock'}
        </button>
        {isAuthenticated && (
          <>
            <button className="product-action-button" onClick={() => handleAction('edit', product)}>Editar</button>
            {isAdmin && <button className="product-action-button delete" onClick={() => handleAction('delete', product)}>Eliminar</button>}
          </>
        )}
      </div>
    </div>
  );
};

const formatNumber = (number) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return '0,00';
  }
  return number
    .toFixed(2) // Mantiene dos decimales
    .replace('.', ',') // Reemplaza el punto decimal por una coma
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
};

const BallCard = ({ model, image, price, onClick }) => {
  return (
    <div className="ball-card-container" onClick={onClick}>
      <img src={image} alt={model} className="ball-card-image" />
      <h3 className="ball-card-title">{model}</h3>
      <p className="ball-card-price">{price}</p>
    </div>
  );
};

const ProductForm = ({ product, onSubmit, onCancel, categories, setCategories }) => {
  const [model, setModel] = useState(product?.model || '');
  const [price, setPrice] = useState(product?.price || '');
  const [description, setDescription] = useState(product?.description || '');
  const [category, setCategory] = useState(product?.category || '');
  const [newCategory, setNewCategory] = useState('');
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState(product?.images || []);
  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState(product?.stock || 0);

  useEffect(() => {
    if (files.length > 0) {
      const fileReaders = [];
      files.forEach((file, index) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviews(prevPreviews => {
            const newPreviews = [...prevPreviews];
            newPreviews[index] = reader.result;
            return newPreviews;
          });
        };
        reader.readAsDataURL(file);
        fileReaders.push(reader);
      });
    }
  }, [files]);

  const handleRemoveImage = (index) => {
    setPreviews(prevPreviews => prevPreviews.filter((_, i) => i !== index));
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let imageUrls = [];
    if (files.length > 0) {
      try {
        const uploadPromises = files.map(file => pinFileToIPFS(file));
        imageUrls = await Promise.all(uploadPromises);
      } catch (error) {
        console.error('Error uploading images to Pinata:', error);
        alert('Error uploading images, please try again.');
        setLoading(false);
        return;
      }
    } else {
      imageUrls = previews;
    }

    const finalCategory = newCategory || category;

    if (newCategory && !categories.includes(newCategory)) {
      setCategories([...categories, newCategory]);
    }

    onSubmit({
      model,
      images: imageUrls,
      price: parseFloat(price),
      description,
      category: finalCategory,
      stock: parseInt(stock)
    });
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="product-form-container">
      <h3>{product ? 'Editar Producto' : 'Agregar Nuevo Producto'}</h3>
      <div className="form-group-container">
        <label htmlFor="model" className="form-label">Modelo</label>
        <input
          type="text"
          id="model"
          value={model}
          onChange={(e) => setModel(e.target.value)}
          required
          className="form-input"
        />
      </div>
      <div className="form-group-container">
        <label htmlFor="images" className="form-label">Imágenes (hasta 3)</label>
        <input type="file" onChange={(e) => setFiles([...files, ...e.target.files])} accept=".jpg,.png" multiple className="form-input" />
        <div className="image-preview-container">
          {previews.map((preview, index) => (
            <div key={index} className="image-preview-wrapper">
              <img src={preview} alt={`Preview ${index + 1}`} className="image-preview" />
              <button type="button" onClick={() => handleRemoveImage(index)} className="remove-image-button">x</button>
            </div>
          ))}
        </div>
      </div>
      <div className="form-group-container">
        <label htmlFor="price" className="form-label">Precio</label>
        <input
          type="number"
          id="price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
          className="form-input"
        />
      </div>
      <div className="form-group-container">
        <label htmlFor="stock" className="form-label">Stock</label>
        <input
          type="number"
          id="stock"
          value={stock}
          onChange={(e) => setStock(e.target.value)}
          required
          className="form-input"
        />
      </div>
      <div className="form-group-container">
        <label htmlFor="description" className="form-label">Descripción</label>
        <textarea
          id="description"
          maxLength={300}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          className="form-textarea"
        />
      </div>
      <div className="form-group-container">
        <label htmlFor="category" className="form-label">Categoría</label>
        <select
          id="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
          className="form-select"
        >
          <option value="">Seleccione una categoría</option>
          {categories.map(cat => (
            <option key={cat} value={cat}>{cat}</option>
          ))}
          <option value="new">Nueva Categoría</option>
        </select>
      </div>
      {category === 'new' && (
        <div className="form-group-container">
          <label htmlFor="newCategory" className="form-label">Nueva Categoría</label>
          <input
            type="text"
            id="newCategory"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            required
            className="form-input"
          />
        </div>
      )}
      <div className="form-actions-container">
        <button type="submit" disabled={loading} className="form-action-button submit">
          Guardar
        </button>
        <button type="button" onClick={onCancel} disabled={loading} className="form-action-button cancel">Cancelar</button>
      </div>
    </form>
  );
};

const ProductDetailModal = ({ product, onClose }) => {
  return (
    <div className="modal-overlay-container">
      <div className="modal-content-container product-detail-modal">
        <h3>{product.model}</h3>
        {product.images && product.images.map((image, index) => (
          <img key={index} src={image} alt={product.model} className="product-detail-image" />
        ))}
        <p><strong>Precio:</strong> ${formatNumber(product.price)}</p>
        <p><strong>Categoría:</strong> {product.category}</p>
        <p><strong>Descripción:</strong> {product.description}</p>
        <p><strong>Stock disponible:</strong> {product.stock}</p>
        <button onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

export default Products;
export { ProductDetailModal };
