import { useEffect } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';

const OrderEmailSender = ({ orderDetails }) => {
  useEffect(() => {
    const sendEmails = async () => {
      const { email, nombre, orderNumber, totalPrice, cartItems } = orderDetails;
      
      const formattedItems = cartItems.map(item => `${item.model} - Cantidad: ${item.quantity} - Precio: $${item.price}`).join('<br>');

      // Email to buyer
      await addDoc(collection(db, 'mail'), {
        to: email,
        message: {
          subject: `Confirmación de pedido #${orderNumber}`,
          html: `
            <h1>Gracias por tu compra, ${nombre}</h1>
            <p>Tu pedido ha sido registrado con el número <strong>${orderNumber}</strong>.</p>
            <p>Total: $${totalPrice.toFixed(2)}</p>
            <p>Detalles del pedido:</p>
            <p>${formattedItems}</p>
          `,
          text: `Gracias por tu compra, ${nombre}. Tu pedido ha sido registrado con el número ${orderNumber}. Total: $${totalPrice.toFixed(2)}`,
        }
      });

      // Email to admin
      await addDoc(collection(db, 'mail'), {
        to: 'admin@example.com', // Reemplaza con el correo del admin
        message: {
          subject: `Nuevo pedido realizado: #${orderNumber}`,
          html: `
            <h1>Nuevo pedido recibido</h1>
            <p>Detalles del comprador:</p>
            <p>Nombre: ${nombre}</p>
            <p>Email: ${email}</p>
            <p>Total: $${totalPrice.toFixed(2)}</p>
            <p>Detalles del pedido:</p>
            <p>${formattedItems}</p>
          `,
          text: `Nuevo pedido recibido: #${orderNumber}. Nombre: ${nombre}, Email: ${email}, Total: $${totalPrice.toFixed(2)}`,
        }
      });
    };

    if (orderDetails) {
      sendEmails();
    }
  }, [orderDetails]);

  return null;
};

export default OrderEmailSender;
