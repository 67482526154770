import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, orderBy, updateDoc, doc, writeBatch, increment, getDoc, deleteDoc } from 'firebase/firestore';
import './AdminDashboard.css';
import Spinner from './Spinner';
import Notification from './Notification';
import DeliveryNote from './DeliverNote';
import { Link } from 'react-router-dom';

// import GenerateFakeOrders from './GenerateFakeOrder.js'; // Asegúrate de ajustar la ruta según tu estructura de carpetas

const formatNumber = (number) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return '0,00';
  }
  return number
    .toFixed(2) // Mantiene dos decimales
    .replace(/\./g, ',') // Reemplaza todos los puntos por comas
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
};

const AdminDashboard = () => {
  const [orders, setOrders] = useState([]);
  const [sales, setSales] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ message: '', show: false });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [view, setView] = useState('orders');
  const [showArchivedOrders, setShowArchivedOrders] = useState(false);
  const [showArchivedSales, setShowArchivedSales] = useState(false);
  const [showArchivedMessages, setShowArchivedMessages] = useState(false);
  const [printOrder, setPrintOrder] = useState(null);
  const [searchOrder, setSearchOrder] = useState('');
  const [searchName, setSearchName] = useState('');

  const handlePrintOrder = (order) => {
    setPrintOrder(order);
    setTimeout(() => {
      window.print();
      setPrintOrder(null);
    }, 0);
  };

  const showMessage = (message) => {
    setNotification({ message, show: true });
    setTimeout(() => setNotification({ message: '', show: false }), 3000);
  };

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    try {
      const ordersCollection = collection(db, 'pedidos');
      const ordersQuery = query(ordersCollection, orderBy('date', 'desc'));
      const ordersSnapshot = await getDocs(ordersQuery);
      const ordersList = ordersSnapshot.docs
        .map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            orderNumber: data.orderNumber,
            ...data,
            date: data.date?.toDate(),
            cartItems: data.cartItems?.map(item => ({
              ...item,
              image: item.images && item.images[0] ? item.images[0] : ''
            })),
            isConfirmed: data.isConfirmed || false
          };
        })
        .filter(order => order.isActive !== showArchivedOrders);
      setOrders(ordersList);
    } catch (error) {
      console.error("Error fetching orders: ", error);
      showMessage('Error fetching orders');
    }
    setLoading(false);
  }, [showArchivedOrders]);

  const fetchSales = useCallback(async () => {
    setLoading(true);
    try {
      const salesCollection = collection(db, 'compras_mercadopago');
      const salesQuery = query(salesCollection, orderBy('date', 'desc'));
      const salesSnapshot = await getDocs(salesQuery);
      const salesList = salesSnapshot.docs
        .map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            orderNumber: data.orderNumber,
            ...data,
            date: data.date?.toDate(),
            cartItems: data.cartItems?.map(item => ({
              ...item,
              image: item.images && item.images[0] ? item.images[0] : ''
            })),
            isPaid: data.isPaid || false,
            isConfirmed: data.isConfirmed || false
          };
        })
        .filter(sale => sale.isActive !== showArchivedSales);
      setSales(salesList);
    } catch (error) {
      console.error("Error fetching sales: ", error);
      showMessage('Error fetching sales');
    }
    setLoading(false);
  }, [showArchivedSales]);

  const fetchMessages = useCallback(async () => {
    setLoading(true);
    try {
      const messagesCollection = collection(db, 'contacts');
      const messagesQuery = query(messagesCollection, orderBy('timestamp', 'desc'));
      const messagesSnapshot = await getDocs(messagesQuery);
      const messagesList = messagesSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate()
        }))
        .filter(message => message.isArchived === showArchivedMessages);
      setMessages(messagesList);
    } catch (error) {
      console.error("Error fetching messages: ", error);
      showMessage('Error fetching messages');
    }
    setLoading(false);
  }, [showArchivedMessages]);

  const handleSearchOrder = useCallback(async () => {
    setLoading(true);
    try {
      const ordersCollection = collection(db, 'pedidos');
      const ordersQuery = query(ordersCollection, orderBy('date', 'desc'));
      const ordersSnapshot = await getDocs(ordersQuery);
      const ordersList = ordersSnapshot.docs
        .map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            orderNumber: data.orderNumber,
            ...data,
            date: data.date?.toDate(),
            cartItems: data.cartItems?.map(item => ({
              ...item,
              image: item.images && item.images[0] ? item.images[0] : ''
            })),
            isConfirmed: data.isConfirmed || false
          };
        })
        .filter(order =>
          order.orderNumber.toString().includes(searchOrder) &&
          order.nombre.toLowerCase().includes(searchName.toLowerCase()) &&
          order.isActive !== showArchivedOrders
        );
      setOrders(ordersList);
    } catch (error) {
      console.error("Error fetching orders: ", error);
      showMessage('Error fetching orders');
    }
    setLoading(false);
  }, [searchOrder, searchName, showArchivedOrders]);

  const handleSearchSale = useCallback(async () => {
    setLoading(true);
    try {
      const salesCollection = collection(db, 'compras_mercadopago');
      const salesQuery = query(salesCollection, orderBy('date', 'desc'));
      const salesSnapshot = await getDocs(salesQuery);
      const salesList = salesSnapshot.docs
        .map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            orderNumber: data.orderNumber,
            ...data,
            date: data.date?.toDate(),
            cartItems: data.cartItems?.map(item => ({
              ...item,
              image: item.images && item.images[0] ? item.images[0] : ''
            })),
            isPaid: data.isPaid || false,
            isConfirmed: data.isConfirmed || false
          };
        })
        .filter(sale =>
          sale.orderNumber.toString().includes(searchOrder) &&
          sale.nombre.toLowerCase().includes(searchName.toLowerCase()) &&
          sale.isActive !== showArchivedSales
        );
      setSales(salesList);
    } catch (error) {
      console.error("Error fetching sales: ", error);
      showMessage('Error fetching sales');
    }
    setLoading(false);
  }, [searchOrder, searchName, showArchivedSales]);

  const handleSearchMessage = useCallback(async () => {
    setLoading(true);
    try {
      const messagesCollection = collection(db, 'contacts');
      const messagesQuery = query(messagesCollection, orderBy('timestamp', 'desc'));
      const messagesSnapshot = await getDocs(messagesQuery);
      const messagesList = messagesSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate()
        }))
        .filter(message =>
          message.email.toLowerCase().includes(searchOrder.toLowerCase()) &&
          message.name.toLowerCase().includes(searchName.toLowerCase()) &&
          message.isArchived === showArchivedMessages
        );
      setMessages(messagesList);
    } catch (error) {
      console.error("Error fetching messages: ", error);
      showMessage('Error fetching messages');
    }
    setLoading(false);
  }, [searchOrder, searchName, showArchivedMessages]);

  const handlePaymentStatus = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    const externalReference = urlParams.get('external_reference');

    if (status && externalReference) {
      try {
        const saleRef = doc(db, 'compras_mercadopago', externalReference);
        const saleDoc = await getDoc(saleRef);

        if (saleDoc.exists) {
          const saleData = saleDoc.data();
          setSelectedOrder({
            ...saleData,
            id: saleDoc.id,
            date: saleData.date?.toDate(),
            cartItems: saleData.cartItems?.map(item => ({
              ...item,
              image: item.images && item.images[0] ? item.images[0] : ''
            }))
          });
          showMessage(`Payment ${status === 'success' ? 'successful' : 'failed'}`);
        }
      } catch (error) {
        console.error("Error fetching sale data: ", error);
        showMessage('Error fetching sale data');
      }
    }
  }, []);

  useEffect(() => {
    fetchOrders();
    fetchSales();
    fetchMessages();
    handlePaymentStatus();
  }, [fetchOrders, fetchSales, fetchMessages, handlePaymentStatus]);

  const hideOrder = async (orderId, collectionName) => {
    if (window.confirm('¿Estás seguro de que quieres archivar este pedido?')) {
      try {
        await updateDoc(doc(db, collectionName, orderId), { isActive: false });
        if (collectionName === 'pedidos') {
          fetchOrders();
        } else {
          fetchSales();
        }
        showMessage('Pedido archivado');
      } catch (error) {
        console.error("Error archiving order: ", error);
        showMessage('Error archivando pedido');
      }
    }
  };

  const restoreOrder = async (orderId, collectionName) => {
    if (window.confirm('¿Estás seguro de que quieres restaurar este pedido?')) {
      try {
        await updateDoc(doc(db, collectionName, orderId), { isActive: true });
        if (collectionName === 'pedidos') {
          fetchOrders();
        } else {
          fetchSales();
        }
        showMessage('Pedido restaurado');
      } catch (error) {
        console.error("Error restoring order: ", error);
        showMessage('Error restaurando pedido');
      }
    }
  };

  const confirmOrder = async (order, collectionName) => {
    if (window.confirm('¿Estás seguro de que quieres confirmar este pedido?')) {
      try {
        const batch = writeBatch(db);

        for (const item of order.cartItems) {
          const productRef = doc(db, 'products', item.id);

          batch.update(productRef, {
            stock: increment(-item.quantity),
            sales: increment(item.quantity) // Incrementa las ventas del producto correctamente
          });
        }

        await batch.commit();
        await updateDoc(doc(db, collectionName, order.id), { isConfirmed: true });
        if (collectionName === 'pedidos') {
          fetchOrders();
        } else {
          fetchSales();
        }
        showMessage('Pedido confirmado');
      } catch (error) {
        console.error("Error confirming order: ", error);
        showMessage('Error confirmando pedido');
      }
    }
  };

  const cancelOrder = async (order, collectionName) => {
    if (window.confirm('¿Estás seguro de que quieres cancelar este pedido? Esto retribuirá el stock y descontará las ventas.')) {
      try {
        const batch = writeBatch(db);

        for (const item of order.cartItems) {
          const productRef = doc(db, 'products', item.id);

          batch.update(productRef, {
            stock: increment(item.quantity),
            sales: increment(-item.quantity) // Descuenta las ventas del producto correctamente
          });
        }

        await batch.commit();
        await updateDoc(doc(db, collectionName, order.id), { isConfirmed: false });
        if (collectionName === 'pedidos') {
          fetchOrders();
        } else {
          fetchSales();
        }
        showMessage('Pedido cancelado');
      } catch (error) {
        console.error("Error canceling order: ", error);
        showMessage('Error cancelando pedido');
      }
    }
  };

  const deleteMessage = async (messageId) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este mensaje?')) {
      try {
        await deleteDoc(doc(db, 'contacts', messageId));
        fetchMessages();
        showMessage('Mensaje eliminado');
      } catch (error) {
        console.error("Error deleting message: ", error);
        showMessage('Error eliminando mensaje');
      }
    }
  };

  const toggleArchiveMessage = async (messageId) => {
    try {
      const messageRef = doc(db, 'contacts', messageId);
      const messageDoc = await getDoc(messageRef);
      const isArchived = messageDoc.data().isArchived;

      await updateDoc(messageRef, { isArchived: !isArchived });
      fetchMessages();
      showMessage(isArchived ? 'Mensaje restaurado' : 'Mensaje archivado');
    } catch (error) {
      console.error("Error toggling message archive status: ", error);
      showMessage('Error cambiando el estado del mensaje');
    }
  };

  const toggleOrderDetails = (orderId) => {
    setSelectedOrder(selectedOrder === orderId ? null : orderId);
  };


  const renderOrders = () => {
    return (
      <section className="orders-section">
        <h2>Pedidos Recibidos</h2>
        <button onClick={() => setShowArchivedOrders(!showArchivedOrders)} className="toggle-archived-btn">
          {showArchivedOrders ? 'Mostrar Activos' : 'Mostrar Archivados'}
        </button>
        <div className="filters">
          <input
            type="text"
            placeholder="Buscar por Número de Pedido"
            value={searchOrder}
            onChange={(e) => {
              setSearchOrder(e.target.value);
              handleSearchOrder();
            }}
            className="filter-input"
          />
          <input
            type="text"
            placeholder="Buscar por Nombre del Cliente"
            value={searchName}
            onChange={(e) => {
              setSearchName(e.target.value);
              handleSearchOrder();
            }}
            className="filter-input"
          />
        </div>

        {loading ? <Spinner /> : orders.length > 0 ? (
          <>
            <ul className="orders-list">
              {orders.map(order => (
                <li key={order.id} className={`order-item ${order.isConfirmed ? 'confirmed' : ''}`}>
                  <h3>Pedido: {order.orderNumber}</h3>
                  <div className="order-header">
                    <div className="button-container">
                      <button onClick={() => toggleOrderDetails(order.id)} className="action-btn detail-btn">
                        {selectedOrder === order.id ? 'Ocultar detalles' : 'Ver pedido'}
                      </button>
                      <button onClick={() => handlePrintOrder(order)} className="action-btn print-btn">Imprimir</button>
                      {!order.isConfirmed ? (
                        <button onClick={() => confirmOrder(order, 'pedidos')} className="action-btn confirm-btn">Confirmar Pedido</button>
                      ) : (
                        <button onClick={() => cancelOrder(order, 'pedidos')} className="action-btn cancel-btn">Cancelar Pedido</button>
                      )}
                      <button onClick={() => order.isActive ? hideOrder(order.id, 'pedidos') : restoreOrder(order.id, 'pedidos')} className="action-btn archive-btn">
                        {order.isActive ? 'Archivar' : 'Restaurar'}
                      </button>
                    </div>
                  </div>

                  {selectedOrder === order.id && (
                    <ul className="order-products">
                      <h4>Productos:</h4>
                      {order.cartItems?.map((item, index) => (
                        <li key={index} className="product-item-admin">
                          <div className="product-info-admin">
                            <p>Cantidad: {item.quantity}</p>
                            <p>Articulo: {item.model}</p>
                            <p>Precio: ${formatNumber(item.price)}</p>
                            {item.image && (<img src={item.image} alt={item.model} className="product-image-admin" />)}
                          </div>
                        </li>
                      ))}
                      <h4>Subtotal: ${formatNumber(order.subtotal)}</h4>
                      <h4>Descuento: ${formatNumber(order.discountAmount)} ({order.discountDetail})</h4>
                    </ul>
                  )}

                  <h4>Total: ${formatNumber(order.totalPrice)}</h4>
                  {selectedOrder === order.id && (
                    <div className="order-details">
                      <p>Fecha: {order.date?.toLocaleString()}</p>
                      <p>Nombre: {order.nombre}</p>
                      <p>Teléfono: {order.telefono}</p>
                      <p>Dirección: {order.domicilio}, {order.localidad}, {order.codigoPostal}</p>
                      <p>Provincia: {order.provincia}</p>
                      <p>País: {order.pais}</p>
                      <p>Información Adicional: {order.informacionAdicional}</p>
                      <p>Estado de Confirmación: {order.isConfirmed ? 'Concretado' : 'Pendiente'}</p>
                     
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p>No hay pedidos para mostrar.</p>
        )}
      </section>
    );
  };

  const renderSales = () => {
    return (
      <section className="orders-section">
        <h2>Ventas con MercadoPago</h2>
        <button onClick={() => setShowArchivedSales(!showArchivedSales)} className="toggle-archived-btn">
          {showArchivedSales ? 'Mostrar Activos' : 'Mostrar Archivados'}
        </button>
        <div className="filters">
          <input
            type="text"
            placeholder="Buscar por Número de Pedido"
            value={searchOrder}
            onChange={(e) => {
              setSearchOrder(e.target.value);
              handleSearchSale();
            }}
            className="filter-input"
          />
          <input
            type="text"
            placeholder="Buscar por Nombre del Cliente"
            value={searchName}
            onChange={(e) => {
              setSearchName(e.target.value);
              handleSearchSale();
            }}
            className="filter-input"
          />
        </div>

        {loading ? <Spinner /> : sales.length > 0 ? (
          <>
            <ul className="orders-list">
              {sales.map(sale => (
                <li key={sale.id} className={`order-item ${sale.isConfirmed ? 'confirmed' : ''}`}>
                  <h3>Venta MercadoPago: {sale.orderNumber}</h3>
                  <div className="order-header">
                    <div className="button-container">
                      <button onClick={() => toggleOrderDetails(sale.id)} className="action-btn detail-btn">
                        {selectedOrder === sale.id ? 'Ocultar detalles' : 'Ver pedido'}
                      </button>
                      <button onClick={() => handlePrintOrder(sale)} className="action-btn print-btn">Imprimir</button>
                      {!sale.isConfirmed ? (
                        <button onClick={() => confirmOrder(sale, 'compras_mercadopago')} className="action-btn confirm-btn">Confirmar Venta</button>
                      ) : (
                        <button onClick={() => cancelOrder(sale, 'compras_mercadopago')} className="action-btn cancel-btn">Cancelar Venta</button>
                      )}
                      <button onClick={() => sale.isActive ? hideOrder(sale.id, 'compras_mercadopago') : restoreOrder(sale.id, 'compras_mercadopago')} className="action-btn archive-btn">
                        {sale.isActive ? 'Archivar' : 'Restaurar'}
                      </button>
                    </div>
                  </div>

                  {selectedOrder === sale.id && (
                    <ul className="order-products">
                      <h4>Productos:</h4>
                      {sale.cartItems?.map((item, index) => (
                        <li key={index} className="product-item-admin">
                          <div className="product-info-admin">
                            <p>Cantidad: {item.quantity}</p>
                            <p>Articulo: {item.model}</p>
                            <p>Precio: ${formatNumber(item.price)}</p>
                            {item.image && (<img src={item.image} alt={item.model} className="product-image-admin" />)}
                          </div>
                        </li>
                      ))}
                      <h4>Subtotal: ${formatNumber(sale.subtotal)}</h4>
                      <h4>Descuento: ${formatNumber(sale.discountAmount)} ({sale.discountDetail})</h4>
                    </ul>
                  )}

                  <h4>Total: ${formatNumber(sale.totalPrice)}</h4>
                  <p>Estado del Pago: {sale.isPaid ? 'Pagado' : 'Pendiente'}</p>

                  {selectedOrder === sale.id && (
                    <div className="order-details">
                      <p>Fecha: {sale.date?.toLocaleString()}</p>
                      <p>Nombre: {sale.nombre}</p>
                      <p>Teléfono: {sale.telefono}</p>
                      <p>Dirección: {sale.domicilio}, {sale.localidad}, {sale.codigoPostal}</p>
                      <p>Provincia: {sale.provincia}</p>
                      <p>País: {sale.pais}</p>
                      <p>Información Adicional: {sale.informacionAdicional}</p>
                      <p>Estado de Confirmación: {sale.isConfirmed ? 'Concretado' : 'Pendiente'}</p>
                  
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p>No hay ventas para mostrar.</p>
        )}
      </section>
    );
  };


  const renderMessages = () => (
    <section className="messages-section">
      <h2>Mensajes de Contacto</h2>
      <button onClick={() => setShowArchivedMessages(!showArchivedMessages)} className="toggle-archived-btn">
        {showArchivedMessages ? 'Ver Activos' : 'Ver Archivados'}
      </button>
      <div className="filters">
        <input
          type="text"
          placeholder="Buscar por Email"
          value={searchOrder}
          onChange={(e) => {
            setSearchOrder(e.target.value);
            handleSearchMessage();
          }}
          className="filter-input"
        />
        <input
          type="text"
          placeholder="Buscar por Nombre"
          value={searchName}
          onChange={(e) => {
            setSearchName(e.target.value);
            handleSearchMessage();
          }}
          className="filter-input"
        />
      </div>
      {loading ? <Spinner /> : messages.length > 0 ? (
        <>
          <ul className="messages-list">
            {messages.map(message => (
              <li key={message.id} className="message-item">
                <div className="message-header">
                  <h3>{message.name}</h3>
                  <div className="button-container">
                    <button onClick={() => toggleArchiveMessage(message.id)} className="action-btn archive-btn">
                      {message.isArchived ? 'Restaurar' : 'Archivar'}
                    </button>
                    <button onClick={() => deleteMessage(message.id)} className="action-btn delete-btn">Eliminar</button>
                  </div>
                </div>
                <p>Email: {message.email}</p>
                <p>Fecha: {message.timestamp?.toLocaleString()}</p>
                <p>Mensaje: {message.message}</p>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <p>No hay mensajes para mostrar.</p>
      )}
    </section>
  );

  return (
    <div className="admin-dashboard">
      <h1>Panel de control</h1>
      <div className="link-buttons-wrapper">
        <Link to="/local" className="link-button subscriptores-btnn">Ventas físicas</Link>
        <Link to="/gestion-stock" className="link-button editor-btn">Gestion Stock</Link>
        <Link to="/subscriptores" className="link-button subscriptores-btn">Lista de Suscriptores</Link>
        <Link to="/editor-masivo" className="link-button editor-btn">Editor masivo</Link>
        <Link to="/reporte-de-ventas" className="link-button subscriptores-btn">Reporte de ventas</Link>
        <Link to="/grafico" className="link-button subscriptores-btn">Gráfico</Link>
        <Link to="/configuracion-descuentos" className="link-button editor-btn">Configurar descuentos</Link>
        <Link to="/historial-ventas" className="link-button subscriptores-btn">Historial de ventas</Link>
      </div>
      <div className="buttons-wrapper">
        <h1>Ventas online</h1>
        <div className="buttons-container">
          <button
            onClick={() => setView('orders')}
            className={`view-toggle-btn ${view === 'orders' ? 'active' : ''}`}
          >
            Pedidos recibidos
          </button>
          <button
            onClick={() => setView('sales')}
            className={`view-toggle-btn ${view === 'sales' ? 'active' : ''}`}
          >
            Ventas con MercadoPago
          </button>
          <button
            onClick={() => setView('messages')}
            className={`view-toggle-btn ${view === 'messages' ? 'active' : ''}`}
          >
            Mensajes recibidos
          </button>
        </div>
      </div>
      <Notification message={notification.message} show={notification.show} />
      {view === 'orders' && renderOrders()}
      {view === 'sales' && renderSales()}
      {view === 'messages' && renderMessages()}
      {printOrder && (
        <div className="print-only">
          <DeliveryNote order={printOrder} />
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;


