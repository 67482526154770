import React, { useState, useEffect, useRef } from 'react';
import { db } from '../firebase';
import { addDoc, collection, getDocs, doc, getDoc, runTransaction } from 'firebase/firestore';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './Cart.css';
import './Receipt.css';
import Spinner from './Spinner';
import Notifications from './Notification';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from './../Logos/ElMachoBebidasNegro.png';
import OrderEmailSender from './OrderEmailSender';

const Cart = ({ cart, removeFromCart, clearCart, updateCartItemQuantity }) => {
  const [showForm, setShowForm] = useState(false);
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [notification, setNotification] = useState({ message: '', show: false });
  const [formData, setFormData] = useState({
    nombre: '', telefono: '', email: '', domicilio: '', localidad: '',
    codigoPostal: '', provincia: '', pais: '', informacionAdicional: ''
  });
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const receiptRef = useRef();
  const [discounts, setDiscounts] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [appliedDiscount, setAppliedDiscount] = useState(null);
  const [showDiscounts, setShowDiscounts] = useState(false);

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'discounts'));
        const fetchedDiscounts = querySnapshot.docs.map(doc => doc.data());
        setDiscounts(fetchedDiscounts);
      } catch (error) {
        console.error("Error fetching discounts:", error);
      }
    };
    fetchDiscounts();
  }, []);

  useEffect(() => {
    const handleReCaptchaVerify = async () => {
      if (executeRecaptcha) {
        try {
          const token = await executeRecaptcha('submit_order');
          setRecaptchaToken(token);
        } catch (error) {
          console.error("Error verifying reCAPTCHA:", error);
          showNotification('Error de reCAPTCHA, por favor intente nuevamente.');
        }
      }
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const showNotification = (message) => {
    setNotification({ message, show: true });
    setTimeout(() => setNotification({ message: '', show: false }), 3000);
  };

  const checkStockAvailability = async () => {
    for (const item of cart) {
      const productRef = doc(db, 'products', item.id);
      const productDoc = await getDoc(productRef);
      const productData = productDoc.data();
      if (!productData || productData.stock < item.quantity) {
        return false;
      }
    }
    return true;
  };

  const getOrderNumber = async (type) => {
    const counterRef = doc(db, 'counters', type === 'sale' ? 'saleCounter' : 'orderCounter');
    try {
      const newOrderNumber = await runTransaction(db, async (transaction) => {
        const counterDoc = await transaction.get(counterRef);
        if (!counterDoc.exists()) {
          throw new Error(`${type === 'sale' ? 'Sale' : 'Order'} counter document does not exist!`);
        }
        const newOrderNumber = counterDoc.data().currentOrderNumber + 1;
        transaction.update(counterRef, { currentOrderNumber: newOrderNumber });
        return newOrderNumber;
      });
      return newOrderNumber;
    } catch (error) {
      console.error("Transaction failed: ", error);
      showNotification(`Error generating ${type === 'sale' ? 'sale' : 'order'} number, please try again.`);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!recaptchaToken) {
      showNotification('Error de reCAPTCHA, por favor intente nuevamente.');
      setIsLoading(false);
      return;
    }

    const isStockAvailable = await checkStockAvailability();
    if (!isStockAvailable) {
      showNotification('No hay inventario disponible, elimine items del carrito agotados.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch('https://us-central1-elmachobebidas.cloudfunctions.net/verifyReCaptcha', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: recaptchaToken }),
      });

      const result = await response.json();
      console.log("reCAPTCHA verification result:", result);

      if (result.success) {
        const orderNumber = await getOrderNumber('order');
        if (!orderNumber) {
          setIsLoading(false);
          return;
        }

        const { subtotal, discountAmount, discountDetail, finalPrice } = calculateTotalPrice();

        const orderData = {
          orderNumber,
          ...formData,
          cartItems: cart,
          subtotal,
          discountAmount,
          discountDetail,
          totalPrice: finalPrice,
          date: new Date(),
          status: 'Pendiente',
        };

        await addDoc(collection(db, 'pedidos'), { ...orderData, isActive: true });

        showNotification("Pedido enviado exitosamente!");
        setOrderDetails(orderData);
        setShowForm(false);
        setFormData({
          nombre: '', telefono: '', email: '', domicilio: '', localidad: '',
          codigoPostal: '', provincia: '', pais: '', informacionAdicional: '',
        });
        clearCart();
        setKeyword('');
        setAppliedDiscount(null);
      } else {
        showNotification("Error de reCAPTCHA, por favor intente nuevamente.");
      }
    } catch (error) {
      console.error("Error al enviar el pedido:", error);
      showNotification("Error al enviar el pedido, por favor intente nuevamente.");
    } finally {
      setIsLoading(false);
    }
  };


  const handleCheckoutPro = async () => {
    setIsLoading(true);

    if (!formData.nombre || !formData.email || !formData.telefono || !formData.domicilio) {
      showNotification('Por favor, complete la información de envío antes de proceder al pago.');
      setIsLoading(false);
      return;
    }

    const isStockAvailable = await checkStockAvailability();
    if (!isStockAvailable) {
      showNotification('No hay inventario disponible, elimine items del carrito agotados.');
      setIsLoading(false);
      return;
    }

    const { subtotal, discountAmount, discountDetail, finalPrice } = calculateTotalPrice();

    const items = cart.map(item => ({
      title: item.model,
      unit_price: Number(item.price),
      quantity: Number(item.quantity),
      id: item.id,
    }));

    const orderNumber = await getOrderNumber('sale');
    if (!orderNumber) {
      setIsLoading(false);
      return;
    }

    const orderData = {
      orderNumber,
      ...formData,
      cartItems: cart,
      subtotal,
      discountAmount,
      discountDetail,
      totalPrice: finalPrice,
      date: new Date(),
      status: 'Procesando',
      isPaid: false
    };

    try {
      const orderRef = await addDoc(collection(db, 'compras_mercadopago'), { ...orderData, isActive: true });

      const itemsWithDiscount = items.map(item => ({
        ...item,
        unit_price: Number(item.unit_price * (1 - discountAmount / subtotal)),
      }));

      const response = await fetch('https://us-central1-elmachobebidas.cloudfunctions.net/createPreference', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          items: itemsWithDiscount,
          payer: {
            name: formData.nombre,
            email: formData.email,
            phone: {
              area_code: '',
              number: formData.telefono,
            },
            address: {
              zip_code: formData.codigoPostal,
              street_name: formData.domicilio,
              street_number: '',
            }
          },
          external_reference: orderRef.id,
          total_amount: finalPrice,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.init_point) {
        setOrderDetails(orderData);
        clearCart();
        setKeyword('');
        setAppliedDiscount(null);
        window.location.href = data.init_point;
      } else {
        showNotification('Error al crear la preferencia de pago, por favor intente nuevamente.');
      }
    } catch (error) {
      console.error('Error al crear la preferencia de pago:', error);
      showNotification('Error al crear la preferencia de pago, por favor intente nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };


  const calculateTotalPrice = () => {
    const totalQuantity = cart.reduce((sum, item) => sum + item.quantity, 0);
    const subtotal = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

    let discountAmount = 0;
    let discountDetail = '';

    if (appliedDiscount && appliedDiscount.type === 'keyword') {
      discountAmount = subtotal * (appliedDiscount.percentage / 100);
      discountDetail = `Descuento aplicado (${appliedDiscount.percentage}%) por palabra clave`;
    } else {
      discounts.forEach(discount => {
        if (discount.type === 'quantity' && totalQuantity >= discount.minQuantity) {
          const currentDiscount = subtotal * (discount.percentage / 100);
          if (currentDiscount > discountAmount) {
            discountAmount = currentDiscount;
            discountDetail = `Descuento aplicado (${discount.percentage}%) por cantidad mínima`;
          }
        } else if (discount.type === 'amount' && subtotal >= discount.minAmount) {
          const currentDiscount = subtotal * (discount.percentage / 100);
          if (currentDiscount > discountAmount) {
            discountAmount = currentDiscount;
            discountDetail = `Descuento aplicado (${discount.percentage}%) por monto mínimo`;
          }
        }
      });
    }

    const finalPrice = subtotal - discountAmount;

    return { subtotal, discountAmount, discountDetail, finalPrice };
  };

  const handleApplyDiscount = () => {
    const foundDiscount = discounts.find(discount => discount.type === 'keyword' && discount.keyword === keyword);
    if (foundDiscount) {
      setAppliedDiscount(foundDiscount);
      showNotification(`Descuento del ${foundDiscount.percentage}% aplicado!`);
    } else {
      showNotification('Código de descuento no válido.');
    }
  };

  const toggleForm = (formType) => {
    if (formType === 'order') {
      setShowForm(!showForm);
      setShowCheckoutForm(false);
    } else if (formType === 'checkout') {
      setShowCheckoutForm(!showCheckoutForm);
      setShowForm(false);
    }
  };

  const handleSaveAsPDF = () => {
    const doc = new jsPDF();
    const receiptElement = document.getElementById('receipt');

    if (receiptElement) {
      doc.html(receiptElement, {
        callback: function (doc) {
          doc.save('receipt.pdf');
        },
        x: 10,
        y: 10,
        width: 180, // ajusta el ancho del contenido PDF a la página A4
        windowWidth: 800, // ajusta el ancho de la ventana para la renderización HTML
      });
    }
  };

  const handleQuantityChange = (e, itemId) => {
    const newQuantity = parseInt(e.target.value);
    updateCartItemQuantity(itemId, newQuantity);
  };

  const formatPrice = (price) => {
    return price
      .toFixed(2) // Mantiene dos decimales
      .replace('.', ',') // Reemplaza el punto decimal por una coma
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
  };
  

  const { subtotal, discountAmount, discountDetail, finalPrice } = calculateTotalPrice();

  return (
    <div className="cart">
      <Notifications message={notification.message} show={notification.show} />
      {isLoading && <Spinner />}
      <h2>Carrito de Compras</h2>
      {cart.length === 0 ? (
        <p>El carrito está vacío.</p>
      ) : (
        <div>
          {cart.map(item => (
            <div key={item.id} className="cart-item">
              <img src={item.images ? item.images[0] : ''} alt={item.model} className="cart-item-image" />
              <div className="cart-item-details">
                <span>{item.model}</span>
                <span>
                  Cantidad:
                  <input
                    type="number"
                    value={item.quantity}
                    min="1"
                    onChange={(e) => handleQuantityChange(e, item.id)}
                    className="quantity-input"
                  />
                </span>
                <span>Precio: ${formatPrice(item.price)}</span>
                <span>Total: ${formatPrice(item.price * item.quantity)}</span>
              </div>
              <button className='local-sales-remove-btn' onClick={() => removeFromCart(item.id)}>Eliminar</button>
            </div>
          ))}
          <div className="cart-total">
            <h3>Subtotal: ${formatPrice(subtotal)}</h3>
            {discountDetail && <h4>{discountDetail}: -${formatPrice(discountAmount)}</h4>}
            <h3>Total: ${formatPrice(finalPrice)}</h3>
            <div className="discount-code">
              <input
                type="text"
                placeholder="Código de descuento"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                className="discount-input"
              />
              <br/>
              <button onClick={handleApplyDiscount} className="view-toggle-btn">
                Aplicar Descuento
              </button>
            </div>
            <div className="cart-buttons">

              <button className="cart-button" onClick={() => toggleForm('order')}>
                {showForm ? 'Ocultar Formulario' : 'Enviar Pedido'}
              </button>
             
              <button className="cart-button" onClick={() => toggleForm('checkout')}>
                {showCheckoutForm ? 'Ocultar Formulario' : 'Comprar con MercadoPago'}
              </button>
            </div>
          </div>
        </div>
      )}
      {showForm && (
        <div className="order-form">
          <h3>Información del Pedido</h3>
          <form onSubmit={handleSubmit}>
            <input type="text" name="nombre" placeholder="Nombre" value={formData.nombre} onChange={handleChange} maxLength={50} required />
            <input type="text" name="telefono" placeholder="Teléfono" value={formData.telefono} onChange={handleChange} maxLength={20} required />
            <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} maxLength={50} required />
            <input type="text" name="domicilio" placeholder="Domicilio" value={formData.domicilio} onChange={handleChange} maxLength={100} required />
            <input type="text" name="localidad" placeholder="Localidad" value={formData.localidad} onChange={handleChange} maxLength={50} required />
            <input type="text" name="codigoPostal" placeholder="Código Postal" value={formData.codigoPostal} onChange={handleChange} maxLength={10} required />
            <input type="text" name="provincia" placeholder="Provincia" value={formData.provincia} onChange={handleChange} maxLength={50} required />
            <input type="text" name="pais" placeholder="País" value={formData.pais} onChange={handleChange} maxLength={50} required /> <br/>
            <textarea maxLength={300} name="informacionAdicional" placeholder="Información Adicional" value={formData.informacionAdicional} onChange={handleChange}></textarea> 
            <br/>
            
            <button type="submit" className="cart-button" disabled={!recaptchaToken || isLoading}>
              {isLoading ? 'Enviando...' : (recaptchaToken ? 'Enviar Pedido' : 'Verificando reCAPTCHA...')}
            </button>
            
          </form>
        </div>
      )}
      {showCheckoutForm && (
        <div className="order-form">
          <h3>Información para Comprar con MercadoPago</h3>
          <form>
            <input type="text" name="nombre" placeholder="Nombre" value={formData.nombre} onChange={handleChange} maxLength={50} required />
            <input type="text" name="telefono" placeholder="Teléfono" value={formData.telefono} onChange={handleChange} maxLength={20} required />
            <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} maxLength={50} required />
            <input type="text" name="domicilio" placeholder="Domicilio" value={formData.domicilio} onChange={handleChange} maxLength={100} required />
            <input type="text" name="localidad" placeholder="Localidad" value={formData.localidad} onChange={handleChange} maxLength={50} required />
            <input type="text" name="codigoPostal" placeholder="Código Postal" value={formData.codigoPostal} onChange={handleChange} maxLength={10} required />
            <input type="text" name="provincia" placeholder="Provincia" value={formData.provincia} onChange={handleChange} maxLength={50} required />
            <input type="text" name="pais" placeholder="País" value={formData.pais} onChange={handleChange} maxLength={50} required />
            <textarea maxLength={300} name="informacionAdicional" placeholder="Información Adicional" value={formData.informacionAdicional} onChange={handleChange}></textarea> <br/>

            <button type="submit" className="cart-button" onClick={handleCheckoutPro} disabled={isLoading}>
              {isLoading ? 'Procesando...' : 'Ir a Mercadopago'}
            </button>

          </form>
        </div>
      )}

      {orderDetails && (
        <>
          <OrderEmailSender orderDetails={orderDetails} />
          <div className="receipt-container">
            <div ref={receiptRef} className="receipt" id="receipt">
              <div className="header">
                <img src={logo} alt="El macho bebidas Logo" className="logo" />
                <div className="company-info">
                  <p>El Macho Bebidas</p>
                  <p>Dirección: Presidente Perón 130, Bell Ville, Cba.</p>
                  <p>Teléfono: (3537)536649 </p>
                  <p>www.elmachobebidas.com</p>
                </div>
              </div>
              <div className="order-info">
                <p><strong>Número de {orderDetails.isPaid ? 'Compra' : 'Pedido'}:</strong> {orderDetails.orderNumber}</p>
                <p><strong>Fecha:</strong> {orderDetails.date.toLocaleString()}</p>
                <p><strong>Nombre:</strong> {orderDetails.nombre}</p>
                <p><strong>Teléfono:</strong> {orderDetails.telefono}</p>
                <p><strong>Email:</strong> {orderDetails.email}</p>
                <p><strong>Domicilio:</strong> {orderDetails.domicilio}</p>
                <p><strong>Localidad:</strong> {orderDetails.localidad}</p>
                <p><strong>Código Postal:</strong> {orderDetails.codigoPostal}</p>
                <p><strong>Provincia:</strong> {orderDetails.provincia}</p>
                <p><strong>País:</strong> {orderDetails.pais}</p>
                <p><strong>Información Adicional:</strong> {orderDetails.informacionAdicional}</p>
              </div>
              <div className="products-info">
                <h4>Productos:</h4>
                <ul>
                  {orderDetails.cartItems.map(item => (
                    <li key={item.id}>{item.model} - Cantidad: {item.quantity} - Precio: ${formatPrice(item.price)}</li>
                  ))}
                </ul>
              </div>

              <h4 className="total-amount">Subtotal: ${formatPrice(orderDetails.subtotal)}</h4>
              <h4 className="total-amount">{orderDetails.discountDetail}: -${formatPrice(orderDetails.discountAmount)}</h4>
              <h4 className="total-amount">Total: ${formatPrice(orderDetails.totalPrice)}</h4>

              <div className="receipt-buttons">
                <button onClick={handleSaveAsPDF}>Guardar como PDF</button>
              </div>
            </div>
          </div>
        </>
      )}

      <button className="discount-button" onClick={() => setShowDiscounts(!showDiscounts)}>
        {showDiscounts ? 'Ocultar Descuentos Disponibles' : 'Mostrar Descuentos Disponibles'}
      </button>

      {showDiscounts && (
        <div className="discount-list">
          <h3 className="discount-list-title">Descuentos Disponibles</h3>
          {discounts.map(discount => (
            <div key={discount.id} className="discount-item">
              {discount.minQuantity && discount.minQuantity !== 'N/A' && (
                <span className="discount-detail">Cantidad mínima: {discount.minQuantity} unidades</span>
              )}
              {discount.minAmount && discount.minAmount !== 'N/A' && (
                <span className="discount-detail">Monto mínimo: ${formatPrice(discount.minAmount)}</span>
              )}
              {discount.percentage && discount.percentage !== 'N/A' && (
                <span className="discount-detail">Descuento: -{discount.percentage}%</span>
              )}
              {discount.keyword && discount.keyword !== 'N/A' && (
                <span className="discount-detail">Con código de descuento</span>
              )}
            </div>
          ))}
        </div>
      )}


    </div>
  );
};

export default Cart;
